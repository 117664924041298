<template>
    <div class="warp">
        <div class="platformStatic">
            <div class="staticList">
                <p class="title">
                    接入概况
                </p>
                <ul class="staticCon">
                    <li>
                        <p class="staticTitle">
                            设备数
                        </p>
                        <p class="staticNum" @click="gotoEquip">
                            100
                        </p>
                    </li>
                    <li>
                        <p class="staticTitle">
                            产品数
                        </p>
                        <p class="staticNum" @click="gotoProduct">
                            100
                        </p>
                    </li>
                    <li>
                        <p class="staticTitle">
                            协议包
                        </p>
                        <p class="staticNum" @click="gotoPackage">
                            20
                        </p>
                    </li>
                    <li>
                        <p class="staticTitle">
                            网络组件
                        </p>
                        <p class="staticNum" @click="gotoUnit">
                            20
                        </p>
                    </li>
                </ul>
            </div>
            <div class="staticList">
                <ul class="cardListWrap">
                    <li class="tabList" @click="visible = true">
                        <a-icon type="plus" style="font-size: 21px; margin-right: 16px" />
                        <span>新增实例</span>
                    </li>
                    <li v-for="item in caseList" :key="item.id" class="tabList">
                        <p>实例名称：{{ item.name }}</p>
                        <p>实例描述：{{ item.remark }}</p>
                        <p>当前接入设备数：{{ item.deviceCount }}</p>
                        <p class="historyData">
                            <span @click="details(item)">详情</span>
                            <span style="margin-left: 25px" @click="onDelete(item.id)">删除</span>
                        </p>
                    </li>
                </ul>
                <Pagination style="margin: 0 25px 25px" :current-page="page" :count="total" @handleChange="changePage" @handleChangeSize="changeSize" />
            </div>
        </div>
        <!-- 新增实例弹窗 -->
        <a-modal
            :width="700"
            title="新增实例"
            ok-text="确认"
            cancel-text="取消"
            :visible="visible"
            @ok="onSubmit"
            @cancel="visible = false">
            <a-form-model
                ref="ruleForm"
                :model="form"
                :rules="rules">
                <a-form-model-item label="实例名称" prop="name">
                    <a-input
                        v-model="form.name"
                        placeholder="最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头" />
                </a-form-model-item>
                <a-form-model-item label="实例描述" prop="desc">
                    <a-textarea
                        v-model="form.desc" />
                </a-form-model-item>
                <a-form-model-item :hidden="list.length === 0" label="选择产品" prop="type">
                    <a-select v-model="form.type" placeholder="请选择产品类型" not-found-content="暂无数据">
                        <a-select-option v-for="item in list" :key="item.id" :value="item.code">
                            {{ item.productName }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item :hidden="list.length > 0">
                    <p style="color: #999; margin: 0">
                        *还未创建泛连接产品，点击进行创建
                    </p>
                    <a-button @click="addProVisible = true">
                        <a-icon type="plus" />添加产品
                    </a-button>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
        <!-- 添加产品弹窗 -->
        <a-modal
            :width="800"
            title="添加产品"
            ok-text="确认"
            cancel-text="取消"
            :visible="addProVisible"
            @ok="addProSubmit"
            @cancel="addProVisible = false">
            <a-form-model
                ref="addProForm"
                :model="addProForm"
                :rules="addProRules">
                <a-form-model-item label="产品名称" prop="name">
                    <a-input
                        v-model="addProForm.name"
                        placeholder="最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头" />
                </a-form-model-item>
                <a-form-model-item label="所属省市" prop="city">
                    <CityChoice :prov-val="provVal" :city-val="cityVal" @getProv="getProvInfo" @getCity="getCityInfo" />
                </a-form-model-item>
                <a-form-model-item label="产品类型" prop="type">
                    <div style="display: flex">
                        <a-select v-model="addProForm.type" style="width: 43%;" placeholder="请选择产品类型">
                            <a-select-option v-for="item in productType" :key="item.id" :value="item.id">
                                {{ item.categoryName }}
                            </a-select-option>
                        </a-select>
                        <div style="display: flex; margin-left: 30px; align-items: center">
                            <a-button type="primary" disabled>
                                查看标准物模型
                            </a-button>
                            <a-radio disabled checked style="margin-left: 10px">
                                自定义物模型
                            </a-radio>
                        </div>
                    </div>
                </a-form-model-item>
                <a-form-model-item label="节点类型" prop="nodeType" style="width: 43%; display: inline-block; margin-right: 4%">
                    <a-select v-model="addProForm.nodeType" placeholder="请选择节点类型">
                        <a-select-option value="1">
                            直连设备
                        </a-select-option>
                        <a-select-option value="2">
                            网关设备
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="接入协议" prop="data" style="width: 43%; display: inline-block;">
                    <a-select v-model="addProForm.data" not-found-content="请先选择节点类型" disabled>
                        <a-select-option value="5">
                            泛连接
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="联网方式" prop="delivery">
                    <a-checkbox-group v-model="addProForm.delivery">
                        <a-checkbox value="蜂窝">
                            蜂窝
                        </a-checkbox>
                        <a-checkbox value="以太">
                            以太
                        </a-checkbox>
                        <a-checkbox value="NB">
                            NB
                        </a-checkbox>
                        <a-checkbox value="WIFI">
                            WIFI
                        </a-checkbox>
                        <a-checkbox value="其他">
                            其他
                        </a-checkbox>
                    </a-checkbox-group>
                </a-form-model-item>
                <a-form-model-item label="产品厂商" prop="firm">
                    <a-input
                        v-model="addProForm.firm"
                        placeholder="最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头" />
                </a-form-model-item>
                <a-form-model-item label="产品型号" prop="model">
                    <a-input
                        v-model="addProForm.model"
                        placeholder="最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头" />
                </a-form-model-item>
                <a-form-model-item label="产品描述" prop="desc">
                    <a-textarea
                        v-model="addProForm.desc" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
        <!-- 查看实例详情弹窗 -->
        <a-modal
            :width="900"
            title="实例详情"
            :visible="isVisible"
            ok-text="确认"
            cancel-text="取消"
            @ok="isVisible = false"
            @cancel="isVisible = false">
            <ul class="caseDetailsWrap">
                <li>
                    <span>服务状态：{{ caseDetails.status | caseStatusTypeFilter }}</span>
                    <span>key：{{ caseDetails.keyInfo }}</span>
                    <span>当前接入设备数：{{ caseDetails.deviceCount }}</span>
                </li>
                <li style="margin: 16px 0">
                    <span>产品描述：{{ caseDetails.remark }}</span>
                    <span>绑定产品：{{ caseDetails.productName }}</span>
                </li>
                <li>
                    <span>创建时间：{{ caseDetails.createTime }}</span>
                    <span>username: {{ caseDetails.mqttUserName }}</span>
                    <span>clientid: {{ caseDetails.mqttClientId }}</span>
                </li>
            </ul>
            <a-table style="margin: 26px 0 20px" table-layout="auto" row-key="id" :locale="{emptyText: '暂无数据'}" :columns="columns" :pagination="false" :data-source="data">
                <template slot="operation" slot-scope="text, record">
                    <a href="javascript:void(0);" @click="gotoDetails(record.id)">详情</a>
                    <a href="javascript:void(0);" style="margin: 0 20px" @click="onEdit(record)">编辑</a>
                    <a href="javascript:void(0);" @click="deleteItem(record.id)">删除</a>
                </template>
            </a-table>
            <Pagination style="margin: 0 25px 25px" :current-page="casePage" :count="caseTotal" @handleChange="caseChangePage" @handleChangeSize="caseChangeSize" />
        </a-modal>
        <!-- 编辑设备弹窗 -->
        <a-modal
            :width="800"
            title="编辑设备信息"
            :visible="editVisible"
            ok-text="确认"
            cancel-text="取消"
            @ok="onEditSubmit"
            @cancel="editVisible = false">
            <a-form-model
                ref="editRuleForm"
                :model="editForm">
                <a-form-model-item label="设备描述" prop="desc">
                    <a-textarea
                        v-model="editForm.desc" />
                </a-form-model-item>
                <a-form-model-item label="位置信息">
                    <Map :is-show-site="false" :search-val="editSearchVal" @onSelect="editChooseSite" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import CityChoice from "@/components/CityChoice"
import Pagination from "@/components/Pagination"
import Map from "@/components/Map"
import { changeProduct, getProductType, noServiceProductList } from '@/api/product'
import { getCaseList, addCase, deleteCase } from '@/api/extensiveProtocol'
import { validatePass } from "@/utils/validate"
import { changeEquip, deleteEquip, getEquipList, searchEquip } from "@/api/equipment"
const columns = [
    {
        title: '设备code',
        dataIndex: 'code',
        key: 'id'
    },
    {
        title: '所属产品',
        dataIndex: 'productName',
        key: 'product'
    },
    {
        title: 'IMEI',
        dataIndex: 'imei',
        key: 'IMEI'
    },
    {
        title: '添加时间',
        dataIndex: 'createTime',
        key: 'time'
    },
    {
        title: '最近在线时间',
        dataIndex: 'latestOnlineTime',
        key: 'onlineTime'
    },
    {
        title: '操作',
        width: '160px',
        dataIndex: 'handle',
        scopedSlots: { customRender: 'operation' }
    },
]
export default {
    name: "Index",
    components: {
        Pagination,
        CityChoice,
        Map
    },
    data() {
        return {
            // 实例详情列表分页
            casePage: 1,
            caseSize: 10,
            caseTotal: 0,
            // 实例列表当前页码
            page: 1,
            // 实例列表每页数据条数
            size: 10,
            // 设备列表每页数据条数
            listSize: 10,
            // 实例列表数据总条数
            total: 0,
            // 新增实例弹窗是否显示
            visible: false,
            // 查看详情弹窗是否显示
            isVisible: false,
            // 添加产品弹窗是否显示
            addProVisible: false,
            // 编辑设备弹窗是否显示
            editVisible: false,
            // 编辑设备弹窗搜索的地址内容
            editSearchVal: '',
            // 实例详情数据
            caseDetails: {},
            // 当前编辑的设备id
            equipId: '',
            // 当前编辑的设备code
            equipCode: '',
            // 产品code
            productCode: '',
            // 编辑设备表单数据
            editForm: {
                desc: ''
            },
            // 当前编辑的设备所属产品code
            editProductCode: '',
            // 添加产品表单数据集合
            addProForm: {
                name: '',
                type: undefined,
                nodeType: undefined,
                data: '5',
                delivery: undefined,
                firm: '',
                model: '',
                desc: ''
            },
            // 产品类型列表
            productType: [],
            // 添加产品弹窗中的省份城市信息
            cityResult: '',
            // 添加产品表单验证规则
            addProRules: {
                name: [
                    { required: true, message: '请输入产品名称', trigger: 'blur' },
                    { validator: validatePass, trigger: 'blur' },
                ],
                type: [{ required: true, message: '请选择产品类型', trigger: 'change' }],
                nodeType: [
                    {
                        required: true,
                        message: '请选择节点类型',
                        trigger: 'change',
                    },
                ],
                data: [
                    { required: true, message: '请选择数据协议', trigger: 'change' },
                ],
                delivery: [{ required: true, message: '请选择联网方式', trigger: 'change' }],
                firm: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                model: [
                    { validator: validatePass, trigger: 'blur' }
                ]
            },
            provVal: '请选择省份',
            cityVal: '请选择城市',
            // 未绑定服务实例的产品集合
            list: [],
            // 实例列表
            caseList: [],
            columns,
            // 设备列表
            data: [],
            form: {
                name: '',
                desc: '',
                type: undefined
            },
            rules: {
                name: [
                    { required: true, message: '请输入产品名称', trigger: 'blur' },
                    { validator: validatePass, trigger: 'blur' },
                ],
                type: [
                    { required: true, message: '请选择产品', trigger: 'change' }
                ]
            }
        }
    },
    created() {
        getProductType().then(res => {
            this.productType = res.data
        }).catch(error => {
            console.log(error)
        })
        this.getNoserviceProduct()
        this.getCaseList(this.page, this.size)
    },
    methods: {
        // 实例详情设备列表选择分页
        caseChangePage(val) {
            this.getList(val, this.caseSize, '', this.productCode, '', '')
        },
        // 实例详情设备列表修改每页数据条数
        caseChangeSize(val) {
            this.caseSize = val
            this.getList(1, val, '', this.productCode, '', '')
        },
        // 获取未绑定实例的产品列表
        getNoserviceProduct() {
            noServiceProductList().then(res => {
                this.list = res.data
            }).catch(error => {
                console.log(error)
            })
        },
        // 获取实例列表数据
        getCaseList(page, size) {
            this.page = page
            getCaseList(page, size).then(res => {
                let info = res.data
                this.caseList = info.records
                this.total = info.total
            }).catch(error => {
                console.log(error)
            })
        },
        // 选中实例列表分页
        changePage(val) {
            this.getCaseList(val, this.size)
        },
        // 选中实例列表每页数据条数
        changeSize(val) {
            this.size = val
            this.getCaseList(1, val)
        },
        // 点击新增实例弹窗中的确定按钮
        onSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (this.list.length === 0) {
                    this.$message.warn('请先添加产品!')
                    return
                }
                if (valid) {
                    let info = this.form
                    addCase(info.name, info.type, info.desc).then(() => {
                        this.visible = false
                        this.$refs.ruleForm.resetFields()
                        this.getNoserviceProduct()
                        this.$message.success('实例添加成功!')
                        this.getCaseList(1, this.size)
                    }).catch(error => {
                        console.log(error)
                    })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 获取用户选择的省份数据
        getProvInfo(val) {
            if (this.provVal !== val) {
                this.cityVal = '请选择城市'
            } else {
                this.cityVal = this.cityVal || '请选择城市'
            }
            this.provVal = val
        },
        // 获取用户选择的省份及城市数据
        getCityInfo(val) {
            this.cityVal = val.split('-')[1]
            this.cityResult = val || ' - '
        },
        // 点击添加产品弹窗中的确认按钮
        addProSubmit() {
            this.$refs.addProForm.validate(valid => {
                if (valid) {
                    let info = this.addProForm,
                        prov = this.cityResult.split('-')[0],
                        city = this.cityResult.split('-')[1],
                        mode = info.delivery.join(','),
                        mData = {
                            properties: [],
                            events: [],
                            services: []
                        }
                    changeProduct(info.name, city, prov, info.type, info.nodeType, info.data, mode, info.model, info.firm, info.desc, mData).then(() => {
                        this.addProVisible = false
                        this.$refs.addProForm.resetFields()
                        this.provVal = '请选择省份'
                        this.cityVal = '请选择城市'
                        this.getNoserviceProduct()
                        this.$message.success('产品添加成功！')
                    }).catch(error => {
                        console.log(error)
                    })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 通过产品cede获取设备列表
        getList(page, size, name, code, statu, mode) {
            this.casePage = page
            getEquipList(page, size, name, code, statu, mode).then(res => {
                let info = res.data
                this.data = info.records
                this.caseTotal = info.total
            }).catch(error => {
                console.log(error)
            })
        },
        // 查看实例详情
        details(val) {
            this.caseDetails = val
            this.productCode = val.productCode
            this.isVisible = true
            this.getList(1, this.caseSize, '', val.productCode, '', '')
        },
        // 点击设备数进入设备列表页
        gotoEquip() {
            this.$router.push('/equip-list')
        },
        // 点击产品数进入产品列表页
        gotoProduct() {
            this.$router.push('/product-list')
        },
        // 点击项目数进入协议包页
        gotoPackage() {
            // this.$router.push('/project-manage')
        },
        // 点击项目数进入网络组件
        gotoUnit() {
            // this.$router.push('/project-manage')
        },
        // 删除实例
        onDelete(val) {
            this.$confirm({
                title: '实例删除',
                content: '确认删除该实例吗',
                okText: '确定',
                centered: true,
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    deleteCase(val).then(() => {
                        this.getCaseList(1, this.size)
                    }).catch(error => {
                        console.log(error)
                    })
                }
            })
        },
        // 点击实例详情弹窗表格中的详情
        gotoDetails(val) {
            this.$router.push(`/equip-list/details?id=${val}`)
        },
        // 点击实例详情弹窗表格中的编辑
        onEdit(val) {
            this.equipId = val.id
            this.equipCode = val.code
            searchEquip(val.id).then((res) => {
                let info = res.data
                this.editProductCode = info.productCode
                this.editSearchVal = info.address || ''
                this.editForm.desc = info.remark
                this.editSite = {
                    siteX: info.longitude || '',
                    siteY: info.latitude || '',
                    site: info.address || ''
                }
            }).catch(error => {
                console.log(error)
            })
            this.editVisible = true
        },
        // 点击编辑设备弹窗中的确定按钮
        onEditSubmit() {
            this.$refs.editRuleForm.validate(valid => {
                if (valid) {
                    changeEquip(this.equipCode, '', this.editSite.siteX, this.editSite.siteY, this.editSite.site, this.editForm.desc, this.equipId).then(() => {
                        this.$message.success('设备编辑成功！')
                        this.editVisible = false
                        this.getList(1, this.listSize, '', this.productCode, '', '')
                    })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 选择编辑设备弹窗中的地址
        editChooseSite(val) {
            this.editSearchVal = val.title
            this.editSite = {
                siteX: val.point.lat,
                siteY: val.point.lng,
                site: val.title
            }
        },
        // 点击实例详情弹窗表格中的删除
        deleteItem(val) {
            this.$confirm({
                title: '设备删除',
                content: '确认删除该设备吗',
                okText: '确定',
                centered: true,
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    deleteEquip(val).then(() => {
                        this.$message.success('设备删除成功！')
                        if (this.productCode !== '') {
                            this.getList(1, this.listSize, '', this.productCode, '', '')
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.warp {
    display: flex;
    min-height: 90vh;
    padding: 25px;
    background: #F0F0F7;
}
::v-deep .ant-pagination {
    text-align: right;
}
::v-deep .ant-pagination-total-text {
    float: left;
}
.platformStatic {
    flex: 1;
}
.staticList {
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 8px;
    margin-bottom: 20px;
    color: #818181;
    .cardListWrap {
        display: flex;
        flex-wrap: wrap;
        margin: 25px 25px 0;
        .tabList {
            width: 23.5%;
            margin: 0 2% 25px 0;
            height: 160px;
            padding: 0 25px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border: 1px solid #F0F0F0;
            color: #4D4F5C;
            background: #fff;
            .historyData {
                text-align: right;
                cursor: pointer;
                color: #1890ff;
                margin: 0;
                span {
                    font-size: 12px;
                }
            }
        }
        .tabList:first-of-type {
            cursor: pointer;
            flex-direction: row;
            align-items: center
        }
        .tabList:nth-of-type(4n) {
            margin-right: 0;
        }
    }
    .title {
        color: #4D4F5C;
        font-size: 16px;
        font-weight: bold;
        padding: 16px;
        margin: 0;
    }
    .staticCon {
        display: flex;
        margin: 0;
        color: #818181;
        li {
            height: 129px;
            border: 1px solid #F0F0F0;
            border-right: none;
            padding: 16px 0 16px 16px;
            flex: 1;
            background-size: 70px;
            background-repeat: no-repeat;
            background-position: right bottom;
            border-bottom: none;
            .staticNum {
                font-weight: bold;
                font-size: 32px;
                color: #4D4F5C;
                cursor: pointer;
            }
        }
        li:nth-of-type(1) {
            border-left: none;
            background-image: url("~@/assets/img/equip.png");
        }
        li:nth-of-type(2) {
            background-image: url("~@/assets/img/product.png");
        }
        li:nth-of-type(3) {
            background-image: url("~@/assets/img/package.png");
        }
        li:last-of-type {
            background-image: url("~@/assets/img/unit.png");
            margin-right: 0;
            border-right: none;
        }
    }
    ::v-deep .ant-tabs-tabpane img{
        margin-bottom: 20px;
    }
}
.caseDetailsWrap li {
    display: flex;
    justify-content: space-between;
}
</style>
