var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warp" },
    [
      _c("div", { staticClass: "platformStatic" }, [
        _c("div", { staticClass: "staticList" }, [
          _c("p", { staticClass: "title" }, [_vm._v(" 接入概况 ")]),
          _c("ul", { staticClass: "staticCon" }, [
            _c("li", [
              _c("p", { staticClass: "staticTitle" }, [_vm._v(" 设备数 ")]),
              _c(
                "p",
                { staticClass: "staticNum", on: { click: _vm.gotoEquip } },
                [_vm._v(" 100 ")]
              ),
            ]),
            _c("li", [
              _c("p", { staticClass: "staticTitle" }, [_vm._v(" 产品数 ")]),
              _c(
                "p",
                { staticClass: "staticNum", on: { click: _vm.gotoProduct } },
                [_vm._v(" 100 ")]
              ),
            ]),
            _c("li", [
              _c("p", { staticClass: "staticTitle" }, [_vm._v(" 协议包 ")]),
              _c(
                "p",
                { staticClass: "staticNum", on: { click: _vm.gotoPackage } },
                [_vm._v(" 20 ")]
              ),
            ]),
            _c("li", [
              _c("p", { staticClass: "staticTitle" }, [_vm._v(" 网络组件 ")]),
              _c(
                "p",
                { staticClass: "staticNum", on: { click: _vm.gotoUnit } },
                [_vm._v(" 20 ")]
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "staticList" },
          [
            _c(
              "ul",
              { staticClass: "cardListWrap" },
              [
                _c(
                  "li",
                  {
                    staticClass: "tabList",
                    on: {
                      click: function ($event) {
                        _vm.visible = true
                      },
                    },
                  },
                  [
                    _c("a-icon", {
                      staticStyle: {
                        "font-size": "21px",
                        "margin-right": "16px",
                      },
                      attrs: { type: "plus" },
                    }),
                    _c("span", [_vm._v("新增实例")]),
                  ],
                  1
                ),
                _vm._l(_vm.caseList, function (item) {
                  return _c("li", { key: item.id, staticClass: "tabList" }, [
                    _c("p", [_vm._v("实例名称：" + _vm._s(item.name))]),
                    _c("p", [_vm._v("实例描述：" + _vm._s(item.remark))]),
                    _c("p", [
                      _vm._v("当前接入设备数：" + _vm._s(item.deviceCount)),
                    ]),
                    _c("p", { staticClass: "historyData" }, [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.details(item)
                            },
                          },
                        },
                        [_vm._v("详情")]
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: { "margin-left": "25px" },
                          on: {
                            click: function ($event) {
                              return _vm.onDelete(item.id)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                    ]),
                  ])
                }),
              ],
              2
            ),
            _c("Pagination", {
              staticStyle: { margin: "0 25px 25px" },
              attrs: { "current-page": _vm.page, count: _vm.total },
              on: {
                handleChange: _vm.changePage,
                handleChangeSize: _vm.changeSize,
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "a-modal",
        {
          attrs: {
            width: 700,
            title: "新增实例",
            "ok-text": "确认",
            "cancel-text": "取消",
            visible: _vm.visible,
          },
          on: {
            ok: _vm.onSubmit,
            cancel: function ($event) {
              _vm.visible = false
            },
          },
        },
        [
          _c(
            "a-form-model",
            { ref: "ruleForm", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "实例名称", prop: "name" } },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder:
                        "最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头",
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "实例描述", prop: "desc" } },
                [
                  _c("a-textarea", {
                    model: {
                      value: _vm.form.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "desc", $$v)
                      },
                      expression: "form.desc",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    hidden: _vm.list.length === 0,
                    label: "选择产品",
                    prop: "type",
                  },
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        placeholder: "请选择产品类型",
                        "not-found-content": "暂无数据",
                      },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    _vm._l(_vm.list, function (item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.code } },
                        [_vm._v(" " + _vm._s(item.productName) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { hidden: _vm.list.length > 0 } },
                [
                  _c("p", { staticStyle: { color: "#999", margin: "0" } }, [
                    _vm._v(" *还未创建泛连接产品，点击进行创建 "),
                  ]),
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.addProVisible = true
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "plus" } }),
                      _vm._v("添加产品 "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: 800,
            title: "添加产品",
            "ok-text": "确认",
            "cancel-text": "取消",
            visible: _vm.addProVisible,
          },
          on: {
            ok: _vm.addProSubmit,
            cancel: function ($event) {
              _vm.addProVisible = false
            },
          },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "addProForm",
              attrs: { model: _vm.addProForm, rules: _vm.addProRules },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "产品名称", prop: "name" } },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder:
                        "最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头",
                    },
                    model: {
                      value: _vm.addProForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.addProForm, "name", $$v)
                      },
                      expression: "addProForm.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "所属省市", prop: "city" } },
                [
                  _c("CityChoice", {
                    attrs: { "prov-val": _vm.provVal, "city-val": _vm.cityVal },
                    on: { getProv: _vm.getProvInfo, getCity: _vm.getCityInfo },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "产品类型", prop: "type" } },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "43%" },
                          attrs: { placeholder: "请选择产品类型" },
                          model: {
                            value: _vm.addProForm.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.addProForm, "type", $$v)
                            },
                            expression: "addProForm.type",
                          },
                        },
                        _vm._l(_vm.productType, function (item) {
                          return _c(
                            "a-select-option",
                            { key: item.id, attrs: { value: item.id } },
                            [_vm._v(" " + _vm._s(item.categoryName) + " ")]
                          )
                        }),
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "margin-left": "30px",
                            "align-items": "center",
                          },
                        },
                        [
                          _c(
                            "a-button",
                            { attrs: { type: "primary", disabled: "" } },
                            [_vm._v(" 查看标准物模型 ")]
                          ),
                          _c(
                            "a-radio",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { disabled: "", checked: "" },
                            },
                            [_vm._v(" 自定义物模型 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: {
                    width: "43%",
                    display: "inline-block",
                    "margin-right": "4%",
                  },
                  attrs: { label: "节点类型", prop: "nodeType" },
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择节点类型" },
                      model: {
                        value: _vm.addProForm.nodeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.addProForm, "nodeType", $$v)
                        },
                        expression: "addProForm.nodeType",
                      },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "1" } }, [
                        _vm._v(" 直连设备 "),
                      ]),
                      _c("a-select-option", { attrs: { value: "2" } }, [
                        _vm._v(" 网关设备 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { width: "43%", display: "inline-block" },
                  attrs: { label: "接入协议", prop: "data" },
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        "not-found-content": "请先选择节点类型",
                        disabled: "",
                      },
                      model: {
                        value: _vm.addProForm.data,
                        callback: function ($$v) {
                          _vm.$set(_vm.addProForm, "data", $$v)
                        },
                        expression: "addProForm.data",
                      },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "5" } }, [
                        _vm._v(" 泛连接 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "联网方式", prop: "delivery" } },
                [
                  _c(
                    "a-checkbox-group",
                    {
                      model: {
                        value: _vm.addProForm.delivery,
                        callback: function ($$v) {
                          _vm.$set(_vm.addProForm, "delivery", $$v)
                        },
                        expression: "addProForm.delivery",
                      },
                    },
                    [
                      _c("a-checkbox", { attrs: { value: "蜂窝" } }, [
                        _vm._v(" 蜂窝 "),
                      ]),
                      _c("a-checkbox", { attrs: { value: "以太" } }, [
                        _vm._v(" 以太 "),
                      ]),
                      _c("a-checkbox", { attrs: { value: "NB" } }, [
                        _vm._v(" NB "),
                      ]),
                      _c("a-checkbox", { attrs: { value: "WIFI" } }, [
                        _vm._v(" WIFI "),
                      ]),
                      _c("a-checkbox", { attrs: { value: "其他" } }, [
                        _vm._v(" 其他 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "产品厂商", prop: "firm" } },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder:
                        "最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头",
                    },
                    model: {
                      value: _vm.addProForm.firm,
                      callback: function ($$v) {
                        _vm.$set(_vm.addProForm, "firm", $$v)
                      },
                      expression: "addProForm.firm",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "产品型号", prop: "model" } },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder:
                        "最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头",
                    },
                    model: {
                      value: _vm.addProForm.model,
                      callback: function ($$v) {
                        _vm.$set(_vm.addProForm, "model", $$v)
                      },
                      expression: "addProForm.model",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "产品描述", prop: "desc" } },
                [
                  _c("a-textarea", {
                    model: {
                      value: _vm.addProForm.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.addProForm, "desc", $$v)
                      },
                      expression: "addProForm.desc",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: 900,
            title: "实例详情",
            visible: _vm.isVisible,
            "ok-text": "确认",
            "cancel-text": "取消",
          },
          on: {
            ok: function ($event) {
              _vm.isVisible = false
            },
            cancel: function ($event) {
              _vm.isVisible = false
            },
          },
        },
        [
          _c("ul", { staticClass: "caseDetailsWrap" }, [
            _c("li", [
              _c("span", [
                _vm._v(
                  "服务状态：" +
                    _vm._s(
                      _vm._f("caseStatusTypeFilter")(_vm.caseDetails.status)
                    )
                ),
              ]),
              _c("span", [_vm._v("key：" + _vm._s(_vm.caseDetails.keyInfo))]),
              _c("span", [
                _vm._v(
                  "当前接入设备数：" + _vm._s(_vm.caseDetails.deviceCount)
                ),
              ]),
            ]),
            _c("li", { staticStyle: { margin: "16px 0" } }, [
              _c("span", [
                _vm._v("产品描述：" + _vm._s(_vm.caseDetails.remark)),
              ]),
              _c("span", [
                _vm._v("绑定产品：" + _vm._s(_vm.caseDetails.productName)),
              ]),
            ]),
            _c("li", [
              _c("span", [
                _vm._v("创建时间：" + _vm._s(_vm.caseDetails.createTime)),
              ]),
              _c("span", [
                _vm._v("username: " + _vm._s(_vm.caseDetails.mqttUserName)),
              ]),
              _c("span", [
                _vm._v("clientid: " + _vm._s(_vm.caseDetails.mqttClientId)),
              ]),
            ]),
          ]),
          _c("a-table", {
            staticStyle: { margin: "26px 0 20px" },
            attrs: {
              "table-layout": "auto",
              "row-key": "id",
              locale: { emptyText: "暂无数据" },
              columns: _vm.columns,
              pagination: false,
              "data-source": _vm.data,
            },
            scopedSlots: _vm._u([
              {
                key: "operation",
                fn: function (text, record) {
                  return [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.gotoDetails(record.id)
                          },
                        },
                      },
                      [_vm._v("详情")]
                    ),
                    _c(
                      "a",
                      {
                        staticStyle: { margin: "0 20px" },
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.onEdit(record)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteItem(record.id)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("Pagination", {
            staticStyle: { margin: "0 25px 25px" },
            attrs: { "current-page": _vm.casePage, count: _vm.caseTotal },
            on: {
              handleChange: _vm.caseChangePage,
              handleChangeSize: _vm.caseChangeSize,
            },
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: 800,
            title: "编辑设备信息",
            visible: _vm.editVisible,
            "ok-text": "确认",
            "cancel-text": "取消",
          },
          on: {
            ok: _vm.onEditSubmit,
            cancel: function ($event) {
              _vm.editVisible = false
            },
          },
        },
        [
          _c(
            "a-form-model",
            { ref: "editRuleForm", attrs: { model: _vm.editForm } },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "设备描述", prop: "desc" } },
                [
                  _c("a-textarea", {
                    model: {
                      value: _vm.editForm.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "desc", $$v)
                      },
                      expression: "editForm.desc",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "位置信息" } },
                [
                  _c("Map", {
                    attrs: {
                      "is-show-site": false,
                      "search-val": _vm.editSearchVal,
                    },
                    on: { onSelect: _vm.editChooseSite },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }