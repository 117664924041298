import request from "@/api/server/index"

// 泛协议实例列表
/*
    param num - 当前页码
    param count - 每页显示数据条数
*/
export function getCaseList(num, count) {
    return request({
        url: '/manager/server/page',
        method: 'post',
        data: {
            page: num,
            pageSize: count
        }
    })
}

// 新增泛协议实例
/*
    param caseName - 实例名称
    param code - 选中的产品编码
    param desc - 实例描述
*/
export function addCase(caseName, code, desc) {
    return request({
        url: '/manager/server/save',
        method: 'post',
        data: {
            name: caseName,
            productCode: code,
            remark: desc
        }
    })
}

// 删除泛协议实例
/*
    param caseId - 当前要删除的实例id
*/
export function deleteCase(caseId) {
    return request({
        url: `/manager/server/del/${caseId}`,
        method: 'post'
    })
}
